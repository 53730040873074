import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [];

const Laboratorio = () => {
  return (
    <Layout>
      <Category data={data} title="Instrumental" />
    </Layout>
  );
};

export default Laboratorio;
